// useNetworkSync.ts
import { useEffect } from 'react';
import indexedDBService from '../services/indexdb-service'; // Import your IndexedDB service
import { AddUpdateFormData } from '../services/form-service';

const sendDataToServer = async (body: { data: any; path: string; type: string }) => {
    const { data } = body;

    const req = { ...data };
    const resp = await AddUpdateFormData(req);

    // const response = await fetch(appConfig.API_URL + path, {
    //     method: type,
    //     headers: {
    //         'Content-Type': 'application/json',
    //     },
    //     body: JSON.stringify(data),
    // });

    // if (!response.ok) {
    //     throw new Error(`Error sending data to server: ${response.statusText}`);
    // }

    return resp;
};

export const syncData = async () => {
    const storedData = await indexedDBService.getItem('Sync', 'AddUpdateFormData');
    if (storedData && storedData.length > 0) {
        const userJson = localStorage.getItem("user");
        if (userJson) {
            const user = JSON.parse(atob(userJson));
            const currentUserData = storedData.filter(x => x.data.customerId === user?.customerId);
            for (const item of currentUserData) {
                try {
                    await sendDataToServer(item);
                    await indexedDBService.deleteItem('Sync', 'AddUpdateFormData');
                } catch (error) {
                    console.error('Error sending data to the server:', error);
                }
            }
        }
    }
};

export const useNetworkSync = () => {
    useEffect(() => {
        // if (navigator.onLine) {
        //     syncData();
        // }

        // window.addEventListener('online', syncData);

        // return () => {
        //     window.removeEventListener('online', syncData);
        // };
    }, []);
};
