export enum RoleEnum {
    SuperAdmin = 1,
    ShoAdmin = 2,
    ShoUser = 3,
    CustomerAdmin = 4,
    CustomerUser = 5
}

export const Role = {
    SuperAdmin: 1,
    ShoAdmin: 2,
    ShoUser: 3,
    CustomerAdmin: 4,
    CustomerUser: 5
}

export const PageEnum = {
    MyForms: "MyForms",
    FormEntires: "FormEntires",
    FormBuilder: "FormBuilder",
    FormSettings: "FormSettings",
    Users: "Users",
    FormGroups: "FormGroups",
    RoleManagement: "RoleManagement",
    ExcelDemo: "ExcelDemo",
}
