import { Fragment, useState, useMemo } from 'react';
import { JsonForms } from '@jsonforms/react';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { makeStyles, ThemeProvider } from '@mui/styles';
import { createTheme } from '@mui/material/styles';
import '../App.css';
import { materialCells, materialRenderers } from '@jsonforms/material-renderers';

//import Ajv from 'ajv';
import SelectControl from '../controls/select/SelectControl';
import selectControlTester from '../controls/select/selectControlTester';
import MediaCaptureControl from '../controls/media-capture/MediaCaptureControl';
import mediaCaptureControlTester from '../controls/media-capture/mediaCaptureControlTester';
import ImageCaptureControl from '../controls/image-capture/ImageCaptureControl';
import imageCaptureControlTester from '../controls/image-capture/imageCaptureControlTester';
import PhotoCaptureControl from '../controls/photo-capture/PhotoCaptureControl';
import photoCaptureControlTester from '../controls/photo-capture/photoCaptureControlTester';

//const addFormats = require('ajv-formats').default;
//const ajv = new Ajv();
//addFormats(ajv);

const theme = createTheme({
    spacing: 4,
});

const useStyles = makeStyles((theme: any) => ({
    container: {
        padding: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    dataContent: {
        background: '#f5f5f5',
        padding: theme.spacing(2),
    },
    resetButton: {
        marginTop: theme.spacing(2),
    },
    demoform: {
        marginTop: theme.spacing(2),
    },
}));

const renderers = [
    ...materialRenderers,
    { tester: selectControlTester, renderer: SelectControl },
    { tester: mediaCaptureControlTester, renderer: MediaCaptureControl },
    { tester: imageCaptureControlTester, renderer: ImageCaptureControl },
    { tester: photoCaptureControlTester, renderer: PhotoCaptureControl },
];

const App = () => {
    const classes = useStyles();
    //const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [data, setData] = useState<any>({
        singleSelect: {
            value: 1,
            code: 'M',
            label: 'Male',
        },
        multiSelect: [
            {
                value: 1,
                code: 'M',
                label: 'Male',
            },
            {
                value: 2,
                code: 'F',
                label: 'Female',
            },
        ],
        countries: [],
    });
    const stringifiedData = useMemo(() => JSON.stringify(data, null, 2), [data]);

    const schema = { "type": "object", "properties": { "date": { "type": "string", "format": "date" }, "time": { "type": "string", "format": "time" }, "locationId": { "type": "number", "title": "Select Location" }, "jobDescription": { "type": "string" }, "workTypes": { "type": "array", "title": "Select Work Types Performed" }, "ppeRequired": { "type": "array", "title": "Select PPE Required" }, "personnelInvolved": { "type": "array", "title": "Personnel Involved", "uniqueItems": true, "items": { "type": "object", "properties": { "firstName": { "type": "string", "maxLength": 10 } } } }, "activities": { "type": "array", "items": { "type": "object", "properties": { "workActivity": { "type": "string", "maxLength": 50 }, "potentialHazard": { "type": "string", "maxLength": 50 }, "controlOfMeasure": { "type": "string", "maxLength": 50 } } } } }, "required": ["date", "time", "locationId", "jobDescription"] };
    const uischema = { "type": "VerticalLayout", "elements": [{ "type": "HorizontalLayout", "elements": [{ "type": "Control", "scope": "#/properties/date" }, { "type": "Control", "scope": "#/properties/time" }] }, { "type": "HorizontalLayout", "elements": [{ "type": "SelectControl", "scope": "#/properties/locationId", "multiple": false, "options": { "asyncEnum": { "url": "https://shotechnology-shoforms-test-api.azurewebsites.net/api/Form/Masters/StateProvince", "method": "GET" } }, "style": { "width": "100%", "maxWidth": "300px" } }, { "type": "Control", "scope": "#/properties/jobDescription", "options": { "multi": true }, "style": { "width": "100%", "maxWidth": "300px" } }] }, { "type": "HorizontalLayout", "elements": [{ "type": "SelectControl", "scope": "#/properties/ppeRequired", "multiple": true, "options": { "asyncEnum": { "url": "https://shotechnology-shoforms-test-api.azurewebsites.net/api/Form/Masters/PPETypes", "method": "GET" } }, "style": { "width": "100%", "maxWidth": "300px" } }, { "type": "SelectControl", "scope": "#/properties/workTypes", "multiple": true, "options": { "asyncEnum": { "url": "https://shotechnology-shoforms-test-api.azurewebsites.net/api/Form/Masters/WorkTypes", "method": "GET" } }, "style": { "width": "100%", "maxWidth": "300px" } }] }, { "type": "HorizontalLayout", "elements": [{ "type": "Control", "scope": "#/properties/personnelInvolved" }] }, { "type": "HorizontalLayout", "elements": [{ "type": "Control", "scope": "#/properties/activities" }] }] };

    //const validate = ajv.compile(schema);
    const handleSubmit = (event: any) => {
        event.preventDefault();
        //if (validate(data)) {
        //    alert(JSON.stringify(data));
        //} else {
        //    
        //}
    };

    const clearData = () => {
        setData({});
    };

    return (
        <ThemeProvider theme={theme}>
            <Fragment>
                <Grid container justifyContent="center" spacing={2} className={classes.container}>
                    <Grid item sm={6}>
                        <Typography variant="h4" className={classes.title}>
                            Bound data
                        </Typography>
                        <div className={classes.dataContent}>
                            <pre id="boundData">{stringifiedData}</pre>
                        </div>
                        <Button
                            className={classes.resetButton}
                            onClick={clearData}
                            color="primary"
                            variant="contained"
                        >
                            Clear data
                        </Button>
                    </Grid>
                    <Grid item sm={6}>
                        <Typography variant="h4" className={classes.title}>
                            Work-Site Safety Form
                        </Typography>
                        <div className="sho-form-container">
                            <JsonForms
                                schema={schema}
                                uischema={uischema}
                                data={data}
                                renderers={renderers}
                                cells={materialCells}
                                onChange={({ errors, data }) => setData(data)}
                            />
                        </div>
                        <Button onClick={handleSubmit} color="primary" variant="contained">
                            Submit Form
                        </Button>
                    </Grid>
                </Grid>
            </Fragment>
        </ThemeProvider>
    );
};

export default App;
