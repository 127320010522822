import { makeStyles } from '@mui/styles';

export const useStyles = makeStyles({
    container: {
        padding: '0 1rem 1rem 1rem',
        width: '100%',
    },
    jsonFormsContainer: {
        width: "100%"
    },
    title: {
        textAlign: 'center',
        padding: '0.25em',
    },
    dataContent: {
        display: 'flex',
        justifyContent: 'center',
        borderRadius: '0.25em',
        backgroundColor: '#cecece',
        marginBottom: '1rem',
    },
    resetButton: {
        margin: 'auto !important',
        display: 'block !important',
    },
    demoform: {
        margin: 'auto',
        padding: '1rem',
    },
    ml10: {
        marginLeft: '10px',
    },
    mt10: {
        marginTop: '10px',
    },
    actionFooter: {
        textAlign: 'center',
        marginTop: '10px',
    },
    formContainer: {
        margin: 'auto',
        padding: '1rem',
    },
    textCenter: {
        textAlign: 'center',
    },
    shoFormContainer: {
        padding: '20px',
    },
    btnWidth: {
        width: "auto"
    },
    buttonsContainer: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        margin: '10px'
    },
    buttonsContainerMobileView: {
        flexDirection: "column",
        gap: "10px",
    },
    cardTitle: {
        margin: "0",
        fontSize: "2rem",
        color: "#1e88e5",
        marginBottom: "10px"
    },
    cardTitleMobileView: {
        fontSize: "1.5rem",
    },
    cardInfoText: {
        margin: "0",
        marginBottom: "10px",
        fontSize: "1rem",
        color: "#1e88e5",
        textAlign: "center"
    }
});