import moment from "moment";
import ShoFormsClient from "../httpClient/ShoFormsClient";
import axios, { AxiosInstance } from "axios";

export const formatDateTime = (date: Date) => {
  if (date) return moment(date).format("MMM Do YYYY, h:mm:ss A");
  return "N/A";
};

export function convertUTCDateToLocalDate(date) {
  var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  var offset = date.getTimezoneOffset() / 60;
  var hours = date.getHours();

  newDate.setHours(hours - offset);

  return newDate;
}

export const getCustomerList = async (Object) => {
  try {
    const instance = ShoFormsClient();
    const response = await instance.get("/Customer/GetCustomerList", Object);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const SaveAccessCode = async (object) => {
  try {
    const instance: AxiosInstance = axios.create({
      baseURL: "https://api.dropboxapi.com",
    });
    const response = await instance.post("/oauth2/token", object);
    return response.data;
  } catch (error) {
    throw error;
  }
};
